import Data from "assets/Data.json"
import { DetailBlock } from "components/DetailBlock"
import { RequestButton } from "components/RequestButton"
import React, { Fragment, useEffect } from "react"
import { useHistory } from "react-router-dom"
import "./Home.scss"
import { changeTitle } from "helpers/helperFunctions"
export const Home = (props) => {
  const history = useHistory()
  useEffect(() => {
    const { pathname } = history.location
    changeTitle(pathname)
  }, [history])
  return (
    <Fragment>
      <div className="container">
        <div className="container-header">
          <div className="header-text">
            <p className="main-text">Standard Timed Text!!</p>
            <p className="secondary-text">
              Closed Captions, Subtitles, Translation and Localization Services
            </p>
          </div>
        </div>
        <div className="container-request">
          <RequestButton
            title={"Get Captions"}
            width={50}
            onClick={() => history.push("/contact-us")}
            className="request-button"
          />
        </div>
        <div className="container-details">
          {Data &&
            Data?.detailData.map((item, index) => (
              <DetailBlock key={index} title={item.title} text={item.text} />
            ))}
        </div>
      </div>
    </Fragment>
  )
}

export default Home
